@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400&display=swap);
*{
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  /* background-color: black; */
  font-family: 'Nunito Sans', sans-serif;
  transition: all 0.2s ease-in-out;
  scroll-behavior: smooth;
  /* width: 100vw; */
  overflow-x: hidden;
}

input{
  outline: none;
  border: none;
}

a{
  text-decoration: none;
  cursor: pointer;
}

body{
  background-color: black;
}

button{
  cursor: pointer;
}

img{
  background-color: transparent;
}

::-webkit-scrollbar{
  width: 15px;
  display: none;
}

::-webkit-scrollbar-button{
  /* background-color: rgb(0, 0, 0); */
  display: none;
}

::-webkit-scrollbar-track{
  background-color: rgb(24, 24, 24);
}

::-webkit-scrollbar-thumb{
  border: 3px solid transparent;
  background-color: rgb(170, 170, 170);
  background-clip: padding-box;
  border-radius: 999px;
  width: 8px;
}

.screen-center{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 900;
  background-color: black;
}

.navbar-container .showoff-icon {
  padding-left: 10px;
  flex: 10% 1;
  margin: 0px;
}

.navbar-container .logo {
  margin: 0px;
  color: #FF8A00;
  font-size: 32px;
  font-weight: 600;
}

.navbar-container .nav-text {
  font-weight: 500;
  font-size: 16px;
}

.navbar-container .nav-text:hover {
  color: #FF8A00 !important;
}

.navbar-container a {
  color: white;
  font-size: 15px;
  font-weight: 300;
  margin: 0px 25px 0px 10px;
  min-width: 50px;
}

.navbar-container a:hover {
  color: #FF8A00;
  cursor: pointer;
}

.navbar-container .middleContainer {
  display: flex;
  align-items: center;
}

.navbar-container .rightSide {
  flex: 10% 1;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-width: 70px;
}

.navbar-container .rightSide img {
  display: block !important;
  overflow: hidden;
  object-fit: cover;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  padding: 7px;
  border-radius: 50%;
}

.navbar-container .rightSide img:hover {
  opacity: .9;
  cursor: pointer;
}

.navbar-container .rightSide .hidden2 {
  display: none !important;
}

.navbar-container .rightSide .user-options-container {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 60px;
  padding: 10px;
  background-color: #0a0a0a;
  flex-direction: column;
  border-radius: 5px;
  z-index: 900;
}

.navbar-container .rightSide .user-options-container:hover .hidden2 {
  display: block !important;
}

.navbar-container .rightSide .user-options-container button {
  font-weight: 200;
  font-size: 15px;
}

.navbar-container .rightSide .user-options-container .user-options {
  color: white;
  border: none;
  padding: 6px 20px;
  margin: 5px auto 0px auto;
  background-color: #0a0a0a;
}

.navbar-container .rightSide .user-options-container .user-options:hover {
  color: #FF8A00;
  cursor: pointer;
}

.navbar-container .rightSide .signin {
  font-weight: 500;
  font-size: 16px;
}

.navbar-container .hamburger-container {
  flex: 10% 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  margin-left: 5px;
  display: none;
  z-index: 100;
  order: -1;
}

.navbar-container .hamburger-container .hamburger-icon {
  cursor: pointer;
}

.navbar-container .hamburger-container .hamburger-icon:hover {
  color: #FF8A00;
}

@media only screen and (max-width: 780px) {
  .navbar-container .showoff-icon {
    text-align: center;
  }
  .navbar-container .rightSide {
    flex: 10% 1;
  }
  .navbar-container .hidden1 {
    transform: translateX(-100%);
  }
  .navbar-container .logo {
    text-align: center;
  }
  .navbar-container .logo {
    padding: 0px !important;
  }
  .navbar-container .middleContainer {
    background-color: #050505;
    position: fixed;
    top: 60px;
    height: calc(100vh - 50px);
    width: 40%;
    flex-direction: column;
    align-items: center;
    order: -1;
    z-index: 10000;
  }
  .navbar-container .middleContainer .nav-text {
    margin: 0px;
    padding: 15px 0px;
    width: 100%;
    text-align: center;
  }
  .navbar-container .middleContainer .nav-text:first-child {
    margin-top: 60px;
  }
  .navbar-container .middleContainer .nav-text:hover {
    background-color: #111111;
  }
  .navbar-container .hamburger-container {
    display: block;
  }
}

@media only screen and (max-width: 490px) {
  .navbar-container .rightSide {
    top: 84px;
  }
  .navbar-container .middleContainer {
    width: 100%;
  }
}
/*# sourceMappingURL=Header.css.map */
.footer {
  width: 100%;
  text-align: center;
  height: 100px;
  margin: 200px 0px 0px 0px;
  background-color: #111111;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.footer p {
  color: #bbbbbb;
  font-weight: 100;
  letter-spacing: 1px;
  font-size: 12px;
  background-color: #111111;
}
/*# sourceMappingURL=Footer.css.map */
.main {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.main .leftSide {
  flex: 30% 1;
  padding: 0 10%;
}

.main .leftSide h1 {
  font-size: 2.8rem;
  color: white;
  width: 380px;
  min-width: 300px;
  max-width: 400px;
  display: block;
  padding: 0px 0px 15px 0px;
}

.main .leftSide h1 p {
  font-size: 2.9rem;
  display: inline;
  padding: 0px;
  margin: 0px;
  color: #FF8A00;
}

.main .leftSide button {
  font-size: 16px;
  display: block;
  border: none;
  width: 100px;
  height: 40px;
  border-radius: 20px;
  color: black;
  font-weight: 600;
  background-color: #FF8A00;
}

.main .leftSide button:hover {
  background-color: #ffa034;
}

.main .leftSide button:active {
  background-color: #9b511c;
}

@media only screen and (max-width: 768px) {
  .main {
    margin: 0px;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }
  .main .leftSide {
    flex: none;
    padding: 0%;
  }
  .main .rightSide {
    display: block;
    height: 200px;
  }
}

@media only screen and (max-width: 480px) {
  .main .leftSide h1 {
    font-size: 2.2rem;
    width: 80vw;
  }
}
/*# sourceMappingURL=LandingPage1.css.map */
.main-2 {
  width: 100%;
  height: 100vh;
  background-color: #1a1a1a;
}

.main-2 h2 {
  display: block;
  font-size: 2rem;
  font-weight: 300;
  padding-top: 50px;
  text-align: center;
  color: #FF8A00;
}
/*# sourceMappingURL=LandingPage2.css.map */
.signin-wrapper {
  margin-top: 50px;
  background-image: url(/static/media/bg.c724c7a5.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.signin-wrapper .radialContainer {
  width: 340px;
  height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: radial-gradient(99.58% 314.73% at 0% 0%, #FF8A00 0%, rgba(0, 0, 0, 0) 100%);
}

.signin-wrapper .radialContainer .signup-container label {
  font-size: 15px;
  padding: 0px 0px 0px 30px;
  margin-top: 10px !important;
}

.signin-wrapper .radialContainer .signup-container label:first-child {
  margin-top: 35px !important;
}

.signin-wrapper .radialContainer .signup-container button {
  margin-top: 30px !important;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 16px;
  display: block;
  border: none;
  width: 80%;
  height: 38px;
  border-radius: 5px;
  color: black;
  font-weight: 600;
  background-color: #FF8A00;
}

.signin-wrapper .radialContainer .signup-container a {
  margin-top: 30px !important;
  color: #FF8A00;
  font-size: 12px;
  -ms-grid-row-align: center;
  align-self: center;
}

.signin-wrapper .radialContainer .signin-container {
  width: 95%;
  height: 95%;
  background-color: #1D1D1D;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.signin-wrapper .radialContainer .signin-container label {
  padding: 5px 0px 5px 30px;
  margin-top: 20px;
}

.signin-wrapper .radialContainer .signin-container label:first-child {
  margin-top: 60px;
}

.signin-wrapper .radialContainer .signin-container input {
  width: 82%;
  height: 32px;
  padding: 2px 10px;
  outline: none;
  font-weight: 500;
  letter-spacing: 1px;
  -ms-grid-row-align: center;
  align-self: center;
  border-radius: 2px;
}

.signin-wrapper .radialContainer .signin-container button {
  margin-top: 40px;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 16px;
  display: block;
  border: none;
  width: 80%;
  height: 38px;
  border-radius: 5px;
  color: black;
  font-weight: 600;
  background-color: #FF8A00;
}

.signin-wrapper .radialContainer .signin-container a {
  margin-top: 40px;
  color: #FF8A00;
  font-size: 12px;
  -ms-grid-row-align: center;
  align-self: center;
}

.signin-wrapper .radialContainer .signin-container a:hover {
  text-decoration: underline;
}
/*# sourceMappingURL=Signin.css.map */
.profile-main {
  margin: 80px 0px 0px 0px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-main .profile-banner-div {
  background-color: #181818;
  background-repeat: no-repeat;
  background-size: cover;
  width: 75%;
  min-width: 590px;
  height: 270px;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
}

.profile-main .profile-banner-div .profile-image-div {
  flex: 20% 1;
  min-width: 250px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-main .profile-banner-div .profile-image-div img {
  -ms-grid-row-align: center;
  align-self: center;
  height: 192px;
  min-height: 150px;
  width: 192px;
  min-width: 150px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  border: 2px solid white;
}

.profile-main .profile-banner-div .profile-details-div {
  flex: 70% 1;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 192px;
}

.profile-main .profile-banner-div .profile-details-div h1 {
  margin-top: calc(100% - 24em)/2;
  font-size: 2.0em;
  padding: 0px;
  height: 2.0em;
  letter-spacing: 3px;
}

.profile-main .profile-banner-div .profile-details-div h2 {
  margin-top: 5px;
  padding: 0px;
  font-size: 15px;
  height: 30px;
  font-weight: 100;
  opacity: 0.8;
}

.profile-main .profile-banner-div .profile-details-div p {
  font-weight: 100;
  margin-top: 10px;
  padding: 0px;
  display: block;
  font-size: 14px;
  width: 90%;
  height: 100px;
  max-height: 100px;
  word-wrap: break-word;
  text-transform: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow-x: hidden;
}

.profile-main .profile-banner-div .profile-details-div .profile-links {
  margin-top: 20px;
  height: 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.profile-main .profile-banner-div .profile-details-div .profile-links .profile-link-icon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  cursor: pointer;
}

.profile-main .profile-banner-div .profile-details-div .profile-links .github:hover {
  color: black;
}

.profile-main .profile-banner-div .profile-details-div .profile-links .linkedin:hover {
  color: #0076B3;
}

.profile-main .profile-banner-div .profile-details-div .profile-links .website:hover {
  color: #FF8A00;
}

.profile-main .upvotes-div {
  margin: 50px 0px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row;
  width: 180px;
}

.profile-main .upvotes-div .upvotes-info h1 {
  color: #FF8A00;
  font-size: 22px;
}

.profile-main .upvotes-div .upvotes-info .no-of-upvotes {
  margin-top: 10px;
  color: white;
  text-align: center;
}

.profile-main .upvotes-div .upvotes-icon {
  width: 60px;
  height: 60px;
}

.profile-main .upvotes-div .upvotes-icon img {
  width: 45px;
  height: 45px;
}

.profile-main .all-projects-div {
  margin-bottom: 50px;
  width: 75%;
  border-radius: 5px;
  background-color: #181818;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-main .all-projects-div h1 {
  width: 100%;
  margin-top: 25px;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #FF8A00;
  text-align: center;
}

.profile-main .all-projects-div .projects {
  margin: 10px 0px;
  padding: 20px 0px;
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.profile-main .all-projects-div .projects .project {
  padding: 20px;
  margin: 15px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.profile-main .all-projects-div .projects .project img {
  display: block !important;
  overflow: hidden;
  border-radius: 5px;
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  max-width: 130px;
  max-height: 130px;
  background-color: #181818;
}

.profile-main .all-projects-div .projects .project h3 {
  margin-top: 12px;
  font-size: 18px;
  font-weight: 400;
}

.profile-main .all-projects-div .projects .project:hover {
  transform: scale(1.03);
}

.profile-main .all-projects-div .projects .project:hover h3 {
  color: #FF8A00;
}

@media only screen and (max-width: 680px) {
  .profile-main .profile-banner-div {
    height: auto;
    padding: 30px 0px 20px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .profile-main .profile-banner-div .profile-details-div {
    margin-top: 10px;
    justify-content: center;
    align-items: center;
  }
  .profile-main .profile-banner-div .profile-details-div p {
    max-width: 60%;
    text-align: center;
  }
  .profile-main .profile-banner-div .profile-details-div .profile-links {
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
/*# sourceMappingURL=Profile.css.map */
.feed-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.feed-container input {
  background-color: #1b1b1b;
  margin-top: 30px;
  padding: 8px 20px;
  border-radius: 5px;
  font-size: 15px;
  width: 69%;
  color: white;
}

.feed-container .results-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 70%;
}

.feed-container .results-container .result {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  background-color: #292929;
  height: 160px;
  border-radius: 5px;
}

.feed-container .results-container .result .result-image {
  flex: 8% 1;
  min-width: 120px;
  min-height: 120px;
  text-align: center;
}

.feed-container .results-container .result .result-image img {
  height: 110px;
  width: 110px;
  min-height: 110px;
  min-width: 110px;
}

.feed-container .results-container .result .result-info {
  align-self: flex-start;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 70% 1;
}

.feed-container .results-container .result .result-info .h2 {
  margin-top: 5px;
  color: #FF8A00;
  font-size: 19px;
}

.feed-container .results-container .result .result-info p {
  padding-right: 20px;
  word-wrap: break-word;
  margin-top: 10px;
  color: white;
  width: 90%;
  font-size: 15px;
  font-weight: 300;
  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.feed-container .load-more {
  font-size: 16px;
  display: block;
  border: none;
  width: 108px;
  height: 42px;
  border-radius: 30px;
  color: black;
  font-weight: 600;
  background-color: #FF8A00;
  margin: 10px 0px 50px 0px;
}

@media only screen and (max-width: 500px) {
  .feed-container .results-container .result {
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
  }
  .feed-container .results-container .result .result-image {
    margin: 0px;
    padding: 0px;
  }
  .feed-container .results-container .result .result-image img {
    padding: 0px;
    margin: 0px;
  }
  .feed-container .results-container .result .result-info {
    padding: 0px;
    margin: 0px;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .feed-container .results-container .result .result-info .h2 {
    margin: 0px;
    font-size: 18px;
  }
  .feed-container .results-container .result .result-info p {
    display: none;
  }
}
/*# sourceMappingURL=Feed.css.map */
.project-container {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.project-container .project-main {
  margin-top: 100px;
  min-width: 634px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-container .project-main .tagnames-container {
  margin: auto 0px 0px 0px;
  background-color: #353535;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  max-width: 90%;
}

.project-container .project-main .tagnames-container .tags {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
}

.project-container .project-main .tagnames-container .tags:nth-child(even) {
  background-color: black;
}

.project-container .project-main .not-full {
  display: none;
}

.project-container .project-main .project-main-banner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #1f1f1f;
  border-radius: 15px;
  height: 250px;
}

.project-container .project-main .project-main-banner .project-logo {
  flex: 32% 1;
  height: 180px;
  min-width: 233px;
  min-height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.project-container .project-main .project-main-banner .project-logo img {
  display: block !important;
  overflow: hidden;
  border-radius: 10px;
  object-fit: cover;
  height: 95%;
  max-height: 180px;
  max-width: 180px;
  height: 170px;
  width: 170px;
}

.project-container .project-main .project-main-banner .project-info {
  flex: 80% 1;
  min-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.project-container .project-main .project-main-banner .project-info h2 {
  font-size: 30px;
  font-weight: 600;
}

.project-container .project-main .project-main-banner .project-info .project-credits {
  font-size: 13px;
  font-weight: 200;
}

.project-container .project-main .project-main-banner .project-info .project-credits emphasize {
  cursor: pointer;
  color: #FF8A00;
  font-weight: 400;
}

.project-container .project-main .project-main-banner .project-info .project-description {
  font-size: 14px;
  margin-top: 15px;
  max-height: 70px;
  min-height: 70px;
  min-width: 500px;
  max-width: 500px;
  font-weight: 200;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;
}

.project-container .project-main .project-main-banner .project-info .tagnames-container {
  margin: auto 0px 0px 0px;
  background-color: transparent;
  border-radius: 1px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  max-width: calc(90%);
}

.project-container .project-main .project-main-banner .project-info .tagnames-container .tags {
  border-radius: 3px;
  margin-right: 5px;
  font-size: 13px;
  font-weight: 200;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
}

.project-container .project-main .project-main-banner .project-info .tagnames-container .tags:nth-child(even) {
  background-color: #0e0e0e;
}

.project-container .project-main .project-main-banner .project-info .tagnames-container .tags:nth-child(odd) {
  background-color: #444444;
}

.project-container .project-main .project-controllers-btns {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 100%;
}

.project-container .project-main .project-controllers-btns .git-icon {
  cursor: pointer;
}

.project-container .project-main .project-controllers-btns .git-icon:hover {
  transform: scale(1.02);
}

.project-container .project-main .project-controllers-btns button {
  background-color: #181818;
  border: 2px solid #FF8A00;
  height: 40px;
  width: 150px;
  border-radius: 2px;
  color: white;
}

.project-container .project-main .project-controllers-btns .get-btn {
  width: 65px;
  margin-left: 20px;
}

.project-container .project-main .project-controllers-btns .upvote-btn {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  align-items: center;
}

.project-container .project-main .project-controllers-btns .upvote-btn .text-up {
  margin: 0px auto;
  width: 60%;
}

.project-container .project-main .project-controllers-btns .upvote-btn .up-amt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto 0px auto auto;
  height: 100%;
  width: 40%;
  background-color: #1b1b1b;
}

.project-container .project-main .carousel {
  color: white;
  text-align: center;
}

.project-container .project-main .carousel img {
  background-color: red;
  height: auto;
  width: 400px;
  object-fit: cover;
}

.project-container .project-about {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.project-container .project-about .abt-head {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 30px 0px 30px 0px;
  color: #FF8A00;
}

.project-container .project-about .project-sections {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.project-container .project-about .project-sections .project-section {
  text-align: center;
  width: 100%;
  margin: 5px 0px 0px 0px;
  padding: 10px 0px 10px 0px;
}

.project-container .project-about .project-sections .project-section h2 {
  font-size: 23px;
  font-weight: 600;
  color: #FF8A00;
}

.project-container .project-about .project-sections .project-section p {
  margin: 10px auto;
  line-height: 2;
  width: 70%;
  word-wrap: break-word;
  font-size: 15px;
  font-weight: 300;
}

@media only screen and (max-width: 750px) {
  .project-container {
    min-width: none !important;
  }
  .project-container .project-main-banner {
    background-color: transparent !important;
    height: auto !important;
    padding: 15px 0px !important;
    flex-direction: column !important;
  }
  .project-container .project-main-banner .project-info {
    min-width: none !important;
    width: 100% !important;
    margin-top: 10px !important;
    justify-content: center;
    align-items: center !important;
  }
  .project-container .project-main-banner .project-info .project-description {
    text-align: center;
  }
  .project-container .project-main-banner .project-info .tagnames-container {
    justify-content: center;
    min-width: none !important;
    width: 50% !important;
  }
  .project-container .project-main-banner .project-info .tagnames-container .tags {
    margin-bottom: 5px;
  }
  .project-container .project-about {
    width: 50% !important;
  }
}

@media only screen and (max-width: 450px) {
  .projects-screenshots h2 {
    margin: 50px 0px 30px 0px;
  }
  .project-sections {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .project-sections .project-section {
    text-align: center;
    padding: 0px 0px 10px 0px;
    margin: 20px 0px 20px 0px !important;
  }
}
/*# sourceMappingURL=Project.css.map */
.about-container {
  height: calc(100vh - 100px);
  width: 100%;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.about-container .about-details {
  background-color: #1b1b1b;
  border-radius: 5px;
  width: 300px;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
}

.about-container .about-details h2 {
  margin: 20px 0px 2px 0px;
}

.about-container .about-details img {
  min-width: 130px;
  min-height: 130px;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 20px 0px 20px 0px;
}

.about-container .about-details h3 {
  margin: 10px 0px 10px 0px;
  cursor: pointer;
}

.about-container .about-details p {
  font-size: 15px;
  font-weight: 200;
}
/*# sourceMappingURL=About.css.map */
.profile-edit-container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.profile-edit-container .profile-edit-main {
  display: flex;
  flex-direction: row;
  width: 85%;
  margin: 30px 0px 50px 0px;
  padding: 20px 0px;
  color: white;
}

.profile-edit-container .profile-edit-main .my-details-container {
  background-color: #0a0a0a;
  border-radius: 5px;
  width: 100%;
  min-width: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.profile-edit-container .profile-edit-main .my-details-container h2 {
  color: #FF8A00;
  font-size: 22px;
  margin: 0px 0px 10px 5%;
}

.profile-edit-container .profile-edit-main .my-details-container label {
  margin: 20px 0px 10px 5%;
  font-size: 15px;
  font-weight: 600;
  width: 90%;
}

.profile-edit-container .profile-edit-main .my-details-container input {
  width: 90%;
  padding: 5px 15px;
  outline: none;
  border: none;
  color: white;
  border-radius: 5px;
  letter-spacing: 1px;
  background-color: #181818;
  height: 35px;
  -ms-grid-row-align: center;
  align-self: center;
}

.profile-edit-container .profile-edit-main .my-details-container textarea {
  -ms-grid-row-align: center;
  align-self: center;
  letter-spacing: 1px;
  width: 90%;
  min-width: 90%;
  max-width: 90%;
  min-height: 80px;
  max-height: 80px;
  padding: 10px 10px;
  outline: none;
  border: none;
  color: white;
  border-radius: 5px;
  background-color: #181818;
}

.profile-edit-container .profile-edit-main .my-details-container button {
  background-color: black;
  color: #FF8A00;
  border: 1px solid #FF8A00;
  min-width: 120px;
  padding: 8px 9px;
  border-radius: 6px;
  -ms-grid-row-align: start;
  align-self: start;
  margin-top: 25px;
  margin-left: 5%;
}

.profile-edit-container .profile-edit-main .my-details-container button:hover {
  background-color: #FF8A00;
  color: black;
}

.profile-edit-container .profile-edit-main .image-container {
  width: 50%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-top: 90px;
  min-height: 650px;
}

.profile-edit-container .profile-edit-main .image-container .image-main {
  width: 85%;
  min-width: 300px;
  max-width: 300px;
  height: 60%;
  border-radius: 5px;
  background-color: #111111;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-edit-container .profile-edit-main .image-container .image-main img {
  width: 180px;
  min-width: 150px;
  height: 180px;
  min-height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-edit-container .profile-edit-main .image-container .image-main img:hover {
  opacity: .6;
  cursor: pointer;
}

.profile-edit-container .profile-edit-main .image-container .image-main .upload-image-btn {
  margin-top: 30px;
  cursor: pointer;
  background-color: black;
  color: #FF8A00;
  border: 1px solid #FF8A00;
  padding: 6px 10px;
  border-radius: 6px;
}

.profile-edit-container .profile-edit-main .image-container .image-main .upload-image-btn:hover {
  background-color: #FF8A00;
  color: black;
}

.profile-edit-container .profile-edit-main .image-container .image-main input[type="file"] {
  display: none;
}

@media only screen and (max-width: 1150px) {
  .profile-edit-container .profile-edit-main {
    flex-direction: column-reverse !important;
    align-items: center;
  }
  .profile-edit-container .profile-edit-main .my-details-container {
    padding: 30px 0px;
    min-width: 200px !important;
  }
  .profile-edit-container .profile-edit-main .image-container {
    max-height: 100px !important;
    margin: 0px !important;
    justify-content: center;
    min-height: 400px !important;
  }
  .profile-edit-container .profile-edit-main .image-container .image-main {
    -ms-grid-row-align: center !important;
    align-self: center !important;
    -ms-grid-column-align: center !important;
        justify-self: center !important;
    min-height: 200px !important;
    height: 350px !important;
  }
  .profile-edit-container .profile-edit-main .image-container .image-main img {
    width: 20% !important;
    height: 20% !important;
  }
}
/*# sourceMappingURL=ProfileEdit.css.map */
.pagenotfound-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FF8A00;
  height: calc(100vh - 150px);
  width: 100%;
}

.pagenotfound-container .pagenotfound-main {
  text-align: center;
}

.pagenotfound-container .pagenotfound-main h1 {
  font-size: 150px;
}

.pagenotfound-container .pagenotfound-main h2 {
  font-size: 40px;
}
/*# sourceMappingURL=PageNotFound.css.map */
.myprojects-container {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: 80px;
}

.myprojects-container .myprojects-main {
  min-height: 90%;
  height: auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: start;
  align-items: flex-start;
}

.myprojects-container .myprojects-main .myprojects-controls {
  display: flex;
  flex-direction: row;
  min-height: 40px;
  align-items: center;
  justify-content: flex-start;
}

.myprojects-container .myprojects-main .myprojects-controls h1 {
  font-size: 24px;
  color: #FF8A00;
}

.myprojects-container .myprojects-main .myprojects-controls button {
  border: 1px solid #FF8A00;
  color: #FF8A00;
  background-color: black;
  border-radius: 5px;
  padding: 7px 10px;
  margin-left: 30px;
  cursor: pointer;
  font-weight: 400;
}

.myprojects-container .myprojects-main .myprojects-controls button:hover {
  color: black;
  background-color: #FF8A00;
}

.myprojects-container .myprojects-main .my-projects-list {
  margin: 20px 0px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
}
/*# sourceMappingURL=MyProjects.css.map */
.project {
  margin: 15px 40px 15px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project .project-img {
  border-radius: 5px;
  height: 130px;
  width: 130px;
  min-height: 120px;
  min-width: 120px;
  max-height: 120px;
  max-width: 120px;
  background-color: #181818;
  object-fit: cover;
  cursor: pointer;
}

.project .name-menu-wrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  height: 88px;
}

.project .name-menu-wrapper .hidden {
  transform: translateY(-100%);
}

.project .name-menu-wrapper .rotate {
  transform: rotate(180deg);
}

.project .name-menu-wrapper .name-icon-container {
  display: flex;
  align-items: center;
  background-color: black;
  width: 100%;
  height: 30px;
  z-index: 100;
}

.project .name-menu-wrapper .name-icon-container h3 {
  margin: 0px auto 0px 10px;
  max-height: 40px;
  font-size: 18px;
  font-weight: 400;
}

.project .name-menu-wrapper .name-icon-container .up-icon-menu {
  margin: 0px 5px 0px 10px;
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.project .name-menu-wrapper .project-menus-container {
  z-index: 10;
  border-radius: 2px;
  background-color: #181818;
  position: absolute;
  top: 30px;
  right: 0px;
}

.project .name-menu-wrapper .project-menus-container h2 {
  font-size: 13px;
  font-weight: 300;
  padding: 5px 7px;
  border-bottom: 0.5px solid #0c0c0c;
  cursor: pointer;
}

.project .name-menu-wrapper .project-menus-container h2:hover {
  color: #FF8A00;
}
/*# sourceMappingURL=ProjectMenu.css.map */
.project-new-container {
  margin-top: 50px;
  width: 100%;
  height: auto;
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.project-new-container h3 {
  font-weight: 400;
  font-size: 16px;
}

.project-new-container button {
  font-size: 13px;
  margin: 10px 0px;
  background-color: black;
  color: #FF8A00;
  border: 1px solid #FF8A00;
  padding: 6px 10px;
  border-radius: 5px;
}

.project-new-container button:hover {
  background-color: #FF8A00;
  color: black;
}

.project-new-container .project-new-main {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
}

.project-new-container .project-new-main .project-new-link {
  padding: 35px;
  background-color: #1b1b1b;
  width: 100%;
  max-width: 820px;
  margin-top: 30px;
  color: white;
}

.project-new-container .project-new-main .project-new-link h3 {
  margin: 0px 0px 15px 0px;
  font-weight: 400;
  font-size: 16px;
}

.project-new-container .project-new-main .project-new-link input {
  width: 100%;
  border-radius: 2px;
  min-height: 10px;
  padding: 7px 10px;
  margin-bottom: 25px;
}

.project-new-container .project-new-main .project-new-link input:last-child {
  margin-bottom: 20px;
}

.project-new-container .project-new-main .project-new-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 820px;
  margin-top: 20px;
  height: 200px;
  padding: 0px 30px;
  background-color: #1b1b1b;
}

.project-new-container .project-new-main .project-new-details .project-new-intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.project-new-container .project-new-main .project-new-details .project-new-intro h3 {
  font-size: 16px;
  font-weight: 400;
  color: white;
  margin: 10px 0px 0px 0px;
}

.project-new-container .project-new-main .project-new-details .project-new-intro input {
  width: 90%;
  max-width: 90%;
  padding: 7px 9px;
  border-radius: 2px;
  margin: 10px 0px 20px 0px;
}

.project-new-container .project-new-main .project-new-details .project-new-intro label {
  font-size: 13px;
  margin: 10px 0px;
  background-color: black;
  color: #FF8A00;
  border: 1px solid #FF8A00;
  padding: 6px 10px;
  border-radius: 5px;
}

.project-new-container .project-new-main .project-new-details .project-new-intro label:hover {
  background-color: #FF8A00;
  color: black;
}

.project-new-container .project-new-main .project-new-details .project-new-intro input[type=file] {
  display: none;
}

.project-new-container .project-new-main .project-new-details .project-new-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 50%;
  min-width: 140px;
}

.project-new-container .project-new-main .project-new-details .project-new-image img {
  border: 5px solid #FF8A00;
  border-radius: 50%;
  min-width: 120px;
  min-height: 120px;
  width: 140px;
  height: 140px;
  object-fit: cover;
  overflow: hidden;
  background-color: #181818;
}

.project-new-container .project-new-main .project-new-desc {
  margin-top: 20px;
  background-color: #1b1b1b;
  width: 100%;
  max-width: 820px;
  padding: 30px;
  color: white;
}

.project-new-container .project-new-main .project-new-desc .project-new-brief-desc textarea {
  margin: 20px 0px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  outline: none;
  border: none;
  line-height: 20px;
  padding: 5px 10px;
  height: 70px;
  min-height: 70px;
  border-radius: 5px;
}

.project-new-container .project-new-main .project-new-desc .sections .section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-new-container .project-new-main .project-new-desc .sections .section input {
  width: 100%;
  max-width: 100%;
  padding: 7px 9px;
  border-radius: 2px;
  margin: 30px 0px 10px 0px;
}

.project-new-container .project-new-main .project-new-desc .sections .section textarea {
  margin: 5px 0px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  outline: none;
  border: none;
  line-height: 20px;
  padding: 5px 10px;
  height: 90px;
  min-height: 90px;
  border-radius: 2px;
}

.project-new-container .project-new-main .project-new-desc .sections .section .delete-icon {
  align-self: flex-end;
  cursor: pointer;
}

.project-new-container .project-new-main .project-new-desc .sections .section .delete-icon:hover {
  color: #FF8A00;
}

.project-new-container .project-new-main .tag-container {
  margin-top: 50px;
  max-width: 820px;
  width: 100%;
  background-color: #1b1b1b;
  padding: 30px;
  color: white;
}

.project-new-container .project-new-main .tag-container h3 {
  font-weight: 400;
  font-size: 16px;
}

.project-new-container .project-new-main .tag-container .tags-placeholder {
  margin-top: 20px;
  height: 25px;
}

.project-new-container .project-new-main .tag-container .tags-placeholder span {
  color: #FF8A00;
}

.project-new-container .project-new-main .tag-container .tags {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.project-new-container .project-new-main .tag-container .tags .tag {
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
}

.project-new-container .project-new-main .tag-container .tags .tag span {
  padding: 3px 10px;
  font-size: 14px;
  background-color: #020202;
}

.project-new-container .project-new-main .tag-container .tags .delete-icon {
  padding: 3px 5px;
  background-color: #141414;
}

.project-new-container .project-new-main .tag-container .tags .delete-icon:hover {
  color: #FF8A00;
}

.project-new-container .project-new-main .tag-container input {
  width: 300px;
  max-width: 95%;
  padding: 7px 9px;
  border-radius: 2px;
  margin: 20px 20px 10px 0px;
}

.project-new-container .project-new-main .submit-btn {
  margin-top: 50px;
  font-size: 17px;
}
/*# sourceMappingURL=ProjectNew.css.map */
