@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400&display=swap');

*{
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  /* background-color: black; */
  font-family: 'Nunito Sans', sans-serif;
  transition: all 0.2s ease-in-out;
  scroll-behavior: smooth;
  /* width: 100vw; */
  overflow-x: hidden;
}

input{
  outline: none;
  border: none;
}

a{
  text-decoration: none;
  cursor: pointer;
}

body{
  background-color: black;
}

button{
  cursor: pointer;
}

img{
  background-color: transparent;
}

::-webkit-scrollbar{
  width: 15px;
  display: none;
}

::-webkit-scrollbar-button{
  /* background-color: rgb(0, 0, 0); */
  display: none;
}

::-webkit-scrollbar-track{
  background-color: rgb(24, 24, 24);
}

::-webkit-scrollbar-thumb{
  border: 3px solid transparent;
  background-color: rgb(170, 170, 170);
  background-clip: padding-box;
  border-radius: 999px;
  width: 8px;
}

.screen-center{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}